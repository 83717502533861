<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-10 col-lg-11 col-xl-9 m-auto">

        <UserContactSearch
            :onShowContacts="showContactsBySearch"
            :onReset="resetContactList"
        ></UserContactSearch>

        <div class="row">
          <div class="col-md-12 mt-2">
            <div class="card" v-for="item in contacts">
              <div class="card-content collapse show" aria-expanded="false">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3">
                      <img style="width: 80%" src="/app-assets/images/avatar.png" alt="avatar" class="img-thumbnail">
                    </div>
                    <div class="col-md-8">
                      <router-link :to="{name: 'company.contacts.details', params:{
                        companyId: $route.params.companyId,
                        moduleId: $route.params.moduleId,
                        menuId: $route.params.menuId,
                        contactId: item.contact_id??0
                      }}"
                                   class="text-dark d-block w-100 h-100"
                      >
                        <p>Name: {{ item.contact_profile.full_name ?? 'N/A' }}</p>
                        <p>Phone: {{ this.formatSingleItem(item, 'phone') }}</p>
                        <p>Email: {{ this.formatSingleItem(item, 'email') }}</p>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-2" v-if="!!pagination.links && !!pagination.meta">
            <Paginate
                v-if="shouldPaginate"
                :meta="pagination.meta"
                :links="pagination.links"
                @pageChangeRequest="paginationLinkClicked"
            >
            </Paginate>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import handleContact from "@/services/modules/contact";
import {inject} from "vue";
import {debounce, has} from 'lodash'
import PaginationLaravel from "@/components/atom/PaginationLaravel";
import UserContactSearch from "@/components/molecule/contact/UserContactSearch";

export default {
  name: 'HomeContact',
  components: {
    Paginate: PaginationLaravel,
    UserContactSearch: UserContactSearch
  },
  data: () => ({
    searchableItems: {
      is_manual: 0,
      full_name: '',
    },
    manualEnableBtn:{
      hovering: false
    },
    contacts: [],
    pagination: {
      links: null,
      meta: null,
      clickedPage: 0
    }
  }),
  watch: {
    'searchableItems.is_manual'(){
      this.getUserContacts()
    },
    'pagination.clickedPage'(val){
      if(val > 0)
      {
        this.getUserContacts();
      }
    }
  },
  computed: {
    isManualEnableBtnPrimary()
    {
      return !(this.manualEnableBtn.hovering || this.searchableItems.is_manual);
    },
    shouldPaginate()
    {
      return !!this.pagination.meta && !!this.pagination.links;
    },
    requestedPage(){
      if(!!this.pagination.clickedPage) return this.pagination.clickedPage
      return '';
    },
    group(){
      if(this.$route.name == 'contacts-employee') return 'employee'
      if(this.$route.name == 'contacts-supplier') return 'supplier'
      if(this.$route.name == 'contacts-member') return 'member'
      if(this.$route.name == 'contacts-customer') return 'customer'
      return ''
    },
  },
  methods: {
    resetContactList(){
      this.getUserContacts(false)
    },
    setPaginationFromRes(res){
      this.pagination.meta = null
      this.pagination.links = null
      if(!!res.data.meta && !!res.data.links)
      {
        this.pagination.links = res.data.links
        this.pagination.meta = res.data.meta
      }
    },
    showContactsBySearch(res){
      this.populateContactsFromRes(res)
    },
    populateContactsFromRes(res){
      if(res.status){
        this.contacts = res.data.data
        this.setPaginationFromRes(res)
      }
    },
    paginationLinkClicked(page){
      this.pagination.clickedPage = page;
    },
    formatSingleItem(item, field) {
      if(has(item, `contact_profile.contact_profile_items.${field}`)) return item.contact_profile.contact_profile_items[field][0].value
      return 'N/A'
    },
    async getUserContacts() {
      let res

      this.loading = true;
      try {
        if(this.searchableItems.is_manual) res = await this.getManualUserContact(this.requestedPage, this.group)
        else res = await this.getCompanyAllContacts(this.$route.params.companyId, this.requestedPage, this.group)

        if (!res.status) {
          this.showError(res.message)
        }
        if (res.status) {
          this.populateContactsFromRes(res)
        }
      } catch (err) {
        if (!err.response) {
          return this.showError('Something is wrong.!!')
        }
        if (err.response) {
          return this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }
    },
    searchManualContacts: debounce(async function()
    {
      if(this.searchUserOwnContacts.full_name == '') return this.showError('Enter full name')
      this.loading = true;

      try {
        let res = await this.searchUserOwnContacts(this.createQuery(this.searchableItems))


        if (!res.status) {
          this.contacts = []
          this.showError(res.message)
        }
        if (res.status) {
          this.contacts = res.data.data
          this.showSuccess(res.message)
        }

      } catch (err) {
        if (!err.response) {
          this.showError('Something is wrong.!!')
        }
        if (err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }
    }, 500)
  },
  mounted() {
    this.getUserContacts()
  },
  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const {
      getManualUserContact,
      getCompanyAllContacts,
      searchUserOwnContacts,
      createQuery,
      loading,
    } = handleContact()

    return {
      getManualUserContact,
      getCompanyAllContacts,
      searchUserOwnContacts,
      createQuery,
      has,
      loading,
      showError,
      showSuccess
    }
  }
}
</script>

<style scoped>
.contactSearch {
  margin-bottom: 10px !important;
}

</style>
